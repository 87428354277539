.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #081e23;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.square {
  border-radius: 20px;
  margin: auto;
  height: 40vh;
  width: 40vh;
  background-image: url("/src/Page/sakura.png");
}

.contact-box {
  flex-direction: row;
  width: 10%;
  justify-content: space-evenly;
  display: inline-flex;
  margin-top: 10pt;

}

.link-color {
  color: inherit;
}

.fade-in {
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.card-container {
  max-width: 450px;

}

.card-content {
  background-color: #00070d;
}

.card-text {
  color: aliceblue;
}

.back {
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
}

.bottom {
  position: absolute;
  bottom: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.bottom-text {
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 10px;
}